/* Template Name: Upwind - Tailwind CSS Landing Page Template
   Author: Shreethemes
   Email: support@shreethemes.in
   Website: https://shreethemes.in
   Version: 1.8.0
   Created: March 2022
   File: Main Css File
*/

@tailwind base;
@tailwind components;
@tailwind utilities;

.nav-item {
   cursor: pointer;
}

.highlight{
   position: relative;
}

.highlight:before {
   content: '';
    width: 100%;
   height: 10px;
   background-color: theme('colors.orange.600');
   opacity: .2;
   position: absolute;
   bottom: 0px;
   }

   .price-calculator .slider {
      -webkit-appearance: none;
      width: 100%;
      height: 4px;
      background: #efefef;
      outline: none;
      opacity: 0.7;
      transition: opacity 0.2s;
      position: relative;
      z-index: 1;
    }
    
    .price-calculator .slider:hover {
      opacity: 1;
    }
    
    .price-calculator .slider::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 20px;
      height: 20px;
      background: theme('colors.orange.600');
      cursor: pointer;
      border-radius: 50%;
      position: relative;
      z-index: 2;
    }
    
    .price-calculator .slider::-moz-range-thumb {
      width: 20px;
      height: 20px;
      background: theme('colors.orange.600');
      cursor: pointer;
      border-radius: 50%;
      position: relative;
      z-index: 2;
    }